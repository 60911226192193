<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12">
          <b-card
            border-variant="primary"
            :title="venue.title"
            bg-variant="transparent"
            class="shadow-none"
          >
          </b-card>
        </b-col>

        <b-col lg="12" class="align-items-center mr-auto">
          <b-card no-body>
            <!-- title and dropdown -->
            <b-card-header class="pb-0">
              <b-dropdown
                no-caret
                right
                :text="datelabel"
                variant="transparent"
                class="chart-dropdown"
                toggle-class="p-50"
                size="sm"
              >
                <b-dropdown-item @click="selectdate(0)">วันนี้</b-dropdown-item>
                <b-dropdown-item @click="selectdate(1)"
                  >เมื่อวาน</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(2)"
                  >7 วันที่ผ่านมา</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(3)"
                  >30 วันที่ผ่านมา</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(4)"
                  >เดือนนี้</b-dropdown-item
                >
                <b-dropdown-item @click="selectdate(5)"
                  >กำหนดเอง</b-dropdown-item
                >
              </b-dropdown>
            </b-card-header>
            <!--/ title and dropdown -->

            <b-form-group v-if="customdate">
              <h5>ระบุช่วงวันที่</h5>
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range' }"
                @input="onDateChange"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="SmartphoneIcon"
            :statistic="Number(alldata.devices).toLocaleString()"
            statistic-title="New Devices"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="UsersIcon"
            color="success"
            :statistic="Number(alldata.sessions).toLocaleString()"
            statistic-title="sessions"
          />
        </b-col>
        <b-col lg="4" sm="6">
          <statistic-card-horizontal
            icon="RepeatIcon"
            color="danger"
            :statistic="Number(alldata.connections).toLocaleString()"
            statistic-title="Connections"
          />
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col lg="6">
          <b-card no-body>
            <b-card-header class="align-items-baseline">
              <div>
                <b-card-title class="mb-25"> Age </b-card-title>
              </div>
            </b-card-header>

            <b-card-body class="pb-0">
              <age :statistic="alldata.agedata"></age>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col lg="6">
          <b-card no-body>
            <b-card-header>
              <b-card-title>Genders</b-card-title>
            </b-card-header>

            <b-card-body>
              <vue-apex-charts
                type="radialBar"
                height="400"
                :options="radialBarChart.chartOptions"
                :series="radialBarChart.series"
                v-if="loaded"
              />
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="6" sm="12">
          <b-card>
            <b-card-title class="mb-1"> Device Brands </b-card-title>

            <vue-apex-charts
              type="donut"
              height="350"
              :options="donutChart.chartOptions"
              :series="donutChart.series"
              v-if="loaded"
            />
          </b-card>
        </b-col>

        <b-col lg="6" sm="12">
          <b-card>
            <b-card-title class="mb-1"> Device Models </b-card-title>

            <vue-apex-charts
              type="donut"
              height="350"
              :options="donutChart2.chartOptions"
              :series="donutChart2.series"
              v-if="loaded"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BTable,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormGroup,
  BFormSelect,
  BOverlay,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";

import CardAnalyticSessionsByDevice from "./CardAnalyticSessionsByDevice.vue";
import CardAnalyticProductOrders from "./CardAnalyticProductOrders.vue";
import CardAnalyticCustomers from "./CardAnalyticCustomers.vue";
import CardAnalyticSalesRadarChart from "./CardAnalyticSalesRadarChart.vue";
import CardAnalyticSupportTracker from "./CardAnalyticSupportTracker.vue";
import CardAnalyticRevenue from "./CardAnalyticRevenue.vue";
import CardAnalyticGoalOverview from "./CardAnalyticGoalOverview.vue";
import CardAnalyticAvgSessions from "./CardAnalyticAvgSessions.vue";
import CardAnalyticSaleLineChart from "./CardAnalyticSaleLineChart.vue";
import CardAnalyticRevenueReport from "./CardAnalyticRevenueReport.vue";
import CardAnalyticEarningsChart from "./CardAnalyticEarningsChart.vue";
import api from "@/api";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
//import ChartjsComponentBarChart from '../../charts-and-maps/charts/chartjs/charts-components/ChartjsComponentBarChart.vue'
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import UserTable from "./usertable.vue";
import Age from "./AgeChart.js";

const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",
  },
};

export default {
  components: {
    BRow,
    BCol,
    flatPickr,
    BDropdownItem,
    BDropdown,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardText,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormGroup,
    BFormSelect,
    CardAnalyticSessionsByDevice,
    CardAnalyticProductOrders,
    CardAnalyticCustomers,
    CardAnalyticSalesRadarChart,
    CardAnalyticSupportTracker,
    CardAnalyticRevenue,
    CardAnalyticGoalOverview,
    CardAnalyticAvgSessions,
    CardAnalyticSaleLineChart,
    CardAnalyticRevenueReport,
    CardAnalyticEarningsChart,
    StatisticCardHorizontal,
    VueApexCharts,
    UserTable,
    BTable,
    Age,
    BOverlay,
  },
  data() {
    return {
      venue: "",
      show: false,
      customdate: false,
      perPage: 10,
      pageOptions: [5, 10, 15],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "title", label: "Venue", sortable: false },
        { key: "qty", label: "Users", sortable: true },
      ],
      /* eslint-disable global-require */
      items: [],
      loaded: false,
      rangeDate: null,

      daterange: 2,
      datelabel: "7 วันที่ผ่านมา",
      alldata: "",

      donutChart: {
        series: [85, 16, 50, 50],
        max: 100,

        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
          ],
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: false,
                    fontSize: "1.5rem",
                    label: "Operational",
                    formatter() {
                      return "31%";
                    },
                  },
                },
              },
            },
          },
          labels: ["Operational", "Networking", "Hiring", "R&D"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      donutChart2: {
        series: [85, 16, 50, 50],

        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series5,
            chartColors.donut.series3,
            chartColors.donut.series2,
          ],
          dataLabels: {
            enabled: true,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: false,
                    fontSize: "1.5rem",
                    label: "Operational",
                    formatter() {
                      return "31%";
                    },
                  },
                },
              },
            },
          },
          labels: ["Operational", "Networking", "Hiring", "R&D"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },

      radialBarChart: {
        series: [0, 0, 0],
        chartOptions: {
          colors: [
            chartColors.donut.series1,
            chartColors.donut.series2,
            chartColors.donut.series4,
          ],
          plotOptions: {
            radialBar: {
              size: 185,
              hollow: {
                size: "25%",
              },
              track: {
                margin: 15,
              },
              dataLabels: {
                name: {
                  fontSize: "2rem",
                  fontFamily: "Montserrat",
                },
                value: {
                  fontSize: "1rem",
                  fontFamily: "Montserrat",
                },
                total: {
                  show: false,
                  fontSize: "1rem",
                  label: "Gender",
                },
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            horizontalAlign: "center",
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["Male", "Female", "Others"],
        },
      },

      timeofday: {
        series: [
          {
            name: "Users",
            data: [
              0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
              0, 0,
            ],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: false },
            toolbar: { show: false },
          },
          states: {
            hover: {
              filter: "none",
            },
          },

          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },

          xaxis: {
            categories: [
              "",
              "1:00AM",
              "",
              "3:00AM",
              "",
              "5:00AM",
              "",
              "7:00AM",
              "",
              "9:00AM",
              "",
              "11:00AM",
              "",
              "1:00PM",
              "",
              "3:00PM",
              "",
              "5:00PM",
              "",
              "7:00PM",
              "",
              "9:00PM",
              "",
              "11:00PM",
              "",
            ],

            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
          },
          tooltip: {
            x: { show: true },
          },
        },
      },

      dayofweek: {
        series: [
          {
            name: "Users",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: false },
            toolbar: { show: false },
          },
          states: {
            hover: {
              filter: "none",
            },
          },

          grid: {
            borderColor: "#ebe9f1",
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },

          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],

            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
          },
          tooltip: {
            x: { show: true },
          },
        },
      },
    };
  },
  beforeMount() {
    const params = {
      venue_id: localStorage.getItem("venue"),
    };
    api
      .post("/partner/getvenue", params)
      .then((response) => {
        this.venue = response.data;
      })
      .catch((error) => {
        // router.push('/pages/not-authorized');
      });

    this.selectdate(this.daterange);
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onDateChange() {
      console.log(this.rangeDate);
      if (this.rangeDate.search("to") > 0) {
        const myArr = this.rangeDate.split(" to ");
        this.fromDate = myArr[0];
        this.toDate = myArr[1];
        this.$nextTick(() => {
          this.loadData();
        });
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    selectdate(daterange) {
      this.loaded = false;
      this.daterange = daterange;
      // alert(daterange);
      var today = this.$moment();

      //  console.log(daterange);
      if (daterange == 0) {
        this.customdate = false;
        this.datelabel = "วันนี้";
        this.fromDate = today.format("YYYY-MM-DD");
        this.toDate = today.format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 1) {
        this.customdate = false;
        this.datelabel = "เมื่อวาน";

        this.fromDate = today.subtract(1, "days").format("YYYY-MM-DD");
        this.toDate = this.fromDate;
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 2) {
        this.datelabel = "7 วันที่ผ่านมา";

        this.fromDate = today.subtract(7, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 3) {
        this.customdate = false;

        this.datelabel = "30 วันที่ผ่านมา";

        this.fromDate = today.subtract(30, "days").format("YYYY-MM-DD");
        this.toDate = this.$moment().format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });

        console.log(this.fromDate);
        console.log(this.toDate);
      } else if (daterange == 4) {
        this.customdate = false;

        this.datelabel = "เดือนนี้";

        this.fromDate = this.$moment().startOf("month").format("YYYY-MM-DD");
        this.toDate = this.$moment().endOf("month").format("YYYY-MM-DD");
        this.$nextTick(() => {
          this.loadData();
        });
      } else if (daterange == 5) {
        this.customdate = true;
        this.datelabel = "กำหนดเอง";

        // this.fromDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        // this.toDate = this.$moment().endOf('month').format('YYYY-MM-DD');
        //  this.$nextTick(() => {
        //    this.loadData();
        //})
        this.fromDate = null;
        this.toDate = null;

        console.log(this.fromDate);
        console.log(this.toDate);
      } else {
        this.fromDate = null;
        this.toDate = null;
      }

      // console.log(currentDateWithFormat);
    },

    loadData() {
      this.show = true;

      const params = {
        fromDate: this.fromDate,
        toDate: this.toDate,
        venue_id: localStorage.getItem("venue"),
      };

      api.post("/dashboard/venueusers", params).then((response) => {
        this.alldata = response.data;
        this.radialBarChart.series = response.data.gender.percentage;
        this.radialBarChart.chartOptions.labels = response.data.gender.labels;

        this.donutChart.series = response.data.analytics.brand.datasets[0].data;
        this.donutChart.chartOptions.labels =
          response.data.analytics.brand.labels;

        this.donutChart2.series =
          response.data.analytics.model.datasets[0].data;
        this.donutChart2.chartOptions.labels =
          response.data.analytics.model.labels;
        this.show = false;
        console.log(response.data);

        this.loaded = true;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
